// 根据createBy 获取名称
export const getCreatedByName = (createBy, userList) => {
    if (!createBy || !userList) return "";
    const exit = userList.filter(item => item.userId === createBy);
    return exit.length > 0 ? exit[0].realName : "";
};

export const getVirtualUsersName = (virtualUids, allVirtuals) => {
    if (!virtualUids || !allVirtuals) return "";
    const numberArray = virtualUids.map(Number);
    return allVirtuals.filter(item => numberArray.includes(item.uid)).map(item => item.nickname).join(", ");
};