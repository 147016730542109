<template>
  <el-dialog
      width="80%"
      :title="!dataForm.id ? '新增礼物' : '编辑礼物'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm"
             ref="dataForm" @keyup.enter.native="beforeSubmit()"
             label-width="120px">

      <el-form-item label="场景：">
        <el-radio-group v-model="dataForm.scene">
          <el-radio disabled :label="1">全部</el-radio>
          <el-radio :label="2">私聊</el-radio>
          <el-radio disabled :label="3">群聊</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="礼物名称：" prop="name">
        <el-input v-model="dataForm.name" placeholder="礼物名称"></el-input>
      </el-form-item>
      <el-form-item label="礼物价格：" prop="virtualPrice">
        <el-input v-model="dataForm.virtualPrice" placeholder="物品价格"></el-input>
      </el-form-item>

      <el-form-item label="礼物图片">
        <el-upload
            class="avatar-uploader"
            :action="ossUploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
          <img v-if="dataForm.image" :src="dataForm.image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="礼物状态" prop="status">
        <el-select
            v-model="dataForm.status"
            clearable
            placeholder="礼物状态"
        >
          <el-option
              v-for="item in statusOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="生效国家" prop="countryCodeList">
        <el-select
            v-model="dataForm.countryCodeList"
            clearable
            multiple
            placeholder="全部国家"
        >
          <el-option
              v-for="item in countryCodeOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>


      <h3>展示干预编辑</h3>
      <el-form-item label="强插位置：" prop="forceIndex">
        <el-input v-model="dataForm.forceIndex" placeholder="强插位置"></el-input>
      </el-form-item>

      <el-form-item label="强插有效期：">
        <el-radio-group v-model="dataForm.forceIndexType">
          <el-radio :label="0">永久</el-radio>
          <el-radio :label="1">有限时间</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="dataForm.forceIndexType === 1" label="有限时间">
        <el-date-picker
            v-model="dataForm.createTimeArr"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
      </el-form-item>


      <el-form-item label="礼物角标：" prop="cornerMark">
        <el-select
            v-model="dataForm.cornerMark"
            clearable
            placeholder="礼物角标"
        >
          <el-option
              v-for="item in cornerMarkOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>


    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="beforeSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>

const sourceDataForm = {
  id: 0,
  name: '',
  image: '',
  scene: 2,
  virtualPrice: 0.00,
  status: 0,
  countryCodeList: [],
  forceIndex: undefined,
  indexEffectiveStartTime: '',
  indexEffectiveEndTime: '',
  cornerMark: 'None',
  forceIndexType: 0,
  createTimeArr: []

}
export default {
  data() {
    return {
      ossUploadUrl: "",
      visible: false,
      roleList: [],
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
      },
      dataRule: {
        'ar.nickname': [
          {required: true, message: '用户名不能为空', trigger: 'blur'}
        ],
        'ar.gender': [
          {required: true, message: '性别不能为空', trigger: 'blur'}
        ],
        'ar.occupation': [
          {required: true, message: '职业不能为空', trigger: 'blur'}
        ],
        'ar.virtualStatus': [
          {required: true, message: '状态不能为空', trigger: 'blur'}
        ],
        'ar.virtualIp': [
          {required: true, message: '人物IP不能为空', trigger: 'blur'}
        ],

        'en.nickname': [
          {required: true, message: '用户名不能为空', trigger: 'blur'}
        ],
        'en.gender': [
          {required: true, message: '性别不能为空', trigger: 'blur'}
        ],
        'en.occupation': [
          {required: true, message: '职业不能为空', trigger: 'blur'}
        ],
        'en.virtualStatus': [
          {required: true, message: '状态不能为空', trigger: 'blur'}
        ],
        'en.virtualIp': [
          {required: true, message: '人物IP不能为空', trigger: 'blur'}
        ]
      },
      statusOpt: [
        {
          value: 0,
          label: '下架'
        },
        {
          value: 1,
          label: '上架'
        }
      ],
      countryCodeOpt: [],
      cornerMarkOpt: [
        {
          value: 'New',
          label: 'New'
        },
        {
          value: 'Hot',
          label: 'Hot'
        },
        {
          value: 'Limited',
          label: 'Limited'
        },
        {
          value: 'None',
          label: 'None'
        }
      ],
    }
  },
  created() {
    this.ossUploadUrl = this.$http.adornUrl(
        `/sys/oss/upload/compress?token=${this.$cookie.get("token")}`
    );
  },
  methods: {
    init(id) {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.dataForm.id = id || 0
      if (this.dataForm.id === 0) {
        // 新增模式
        this.visible = true
        return;
      }
      this.$nextTick(() => {
        if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()
      })
      this.$http({
        url: this.$http.adornUrl(`/admin/gift/detail`),
        method: 'get',
        params: this.$http.adornParams({id: this.dataForm.id})
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.visible = true

          this.dataForm.id = data.result.id
          this.dataForm.name = data.result.name
          this.dataForm.image = data.result.image
          this.dataForm.scene = data.result.scene
          this.dataForm.virtualPrice = data.result.virtualPrice
          this.dataForm.status = data.result.status
          this.dataForm.countryCodeList = data.result.countryCodeList
          this.dataForm.forceIndex = data.result.forceIndex
          this.dataForm.indexEffectiveStartTime = data.result.indexEffectiveStartTime
          this.dataForm.indexEffectiveEndTime = data.result.indexEffectiveEndTime
          this.dataForm.cornerMark = data.result.cornerMark
          this.dataForm.forceIndexType = data.result.forceIndexType
          this.dataForm.createTimeArr[0] = data.result.indexEffectiveStartTime
          this.dataForm.createTimeArr[1] = data.result.indexEffectiveEndTime

        }
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或者png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    handleAvatarSuccess(res, file) {
      if (res && res.code === 0) {
        this.dataForm.image = res.url
      } else {
        this.$message.error('上传图片失败:' + res.msg)
      }
    },
    beforeSubmit() {
      if (this.dataForm.id) {
        this.$confirm('确认？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dataFormSubmit();
        })
      } else {
        this.dataFormSubmit();
      }
    },
    // 表单提交
    dataFormSubmit() {

      this.dataForm.indexEffectiveStartTime = (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null;
      this.dataForm.indexEffectiveEndTime = (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null;


      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/admin/gift/${!this.dataForm.id ? 'add' : 'update'}`),
            method: 'post',
            data: this.$http.adornData({
              ...this.dataForm
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>