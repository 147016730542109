<template>
  <div>
    <el-form
        :inline="true"
        :model="dataForm"
        @keyup.enter.native="getDataList()"
    >

      <el-form-item label="配置日期">
        <el-date-picker
            v-model="dataForm.createTimeArr"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="礼物">
        <el-input
            v-model="dataForm.id"
            placeholder="礼物id"
            clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="礼物名称">
        <el-input
            v-model="dataForm.name"
            placeholder="礼物名称"
            clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="展示场景">
        <el-select
            v-model="dataForm.scene"
            clearable
            placeholder="展示场景"
        >
          <el-option
              v-for="item in sceneOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="礼物状态">
        <el-select
            v-model="dataForm.status"
            clearable
            placeholder="礼物状态"
        >
          <el-option
              v-for="item in statusOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="上架区域">
        <el-select
            v-model="dataForm.countryCodeList"
            clearable
            multiple
            placeholder="上架区域"
        >
          <el-option
              v-for="item in countryCodeOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="是否强插">
        <el-select
            v-model="dataForm.forceIndexCondition"
            clearable
            placeholder="是否强插"
        >
          <el-option
              v-for="item in forceIndexConditionOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="配置人">
        <el-select
            v-model="dataForm.updatePerson"
            clearable
            placeholder="配置人"
        >
          <el-option
              v-for="item in sysUser"
              :key="item.userId"
              :label="item.realName"
              :value="item.userId"
          >
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="refresh()">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button @click="addOrUpdateHandle(0)" type="primary">新增礼物</el-button>
    <el-button @click="exportList()" type="primary">导出</el-button>
    <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%"
    >
      <el-table-column
          prop="id"
          width="200px"
          header-align="center"
          align="center"
          label="礼物ID"
      >
      </el-table-column>
      <el-table-column
          prop="name"
          width="150px"
          header-align="center"
          align="center"
          label="礼物名称"
      >
      </el-table-column>

      <el-table-column
          width="100px"
          prop="scene"
          header-align="center"
          align="center"
          label="展示场景"
      >
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.scene === 1" type="success">全部</el-tag>
            <el-tag v-else-if="scope.row.scene === 2" type="success">私聊场景</el-tag>
            <el-tag v-else-if="scope.row.scene === 3" type="success">群聊场景</el-tag>
            <el-tag v-else type="danger">未知</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          label="货币类型"
      >
        <template>
          <span>灵力</span>
        </template>
      </el-table-column>

      <el-table-column
          prop="image"
          header-align="center"
          align="center"
          label="图片"
          width="100"
      >
        <template slot-scope="scope">
          <img style="width: 40px; height: 40px" :src="scope.row.image"/>
        </template>
      </el-table-column>

      <el-table-column
          prop="virtualPrice"
          header-align="center"
          align="center"
          label="礼物价格"
      >
      </el-table-column>

      <el-table-column
          prop="status"
          header-align="center"
          align="center"
          label="礼物状态"
      >
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.status === 0" type="danger">下架</el-tag>
            <el-tag v-else-if="scope.row.status === 1" type="success">上架</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          width="100px"
          prop="countryCodeListString"
          header-align="center"
          align="center"
          label="上架区域"
      >
      </el-table-column>

      <el-table-column
          prop="forceIndex"
          header-align="center"
          align="center"
          label="强插位置"
      >
      </el-table-column>

      <el-table-column
          header-align="center"
          width="200px"
          align="center"
          label="强插时间"
      >
        <template slot-scope="scope">
          <div>
            <span v-if="scope.row.forceIndexType === 0">永久</span>
            <span v-else-if="scope.row.forceIndex && scope.row.forceIndexType === 1">{{
                scope.row.indexEffectiveStartTime
              }} <br>~<br> {{ scope.row.indexEffectiveEndTime }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          prop="updatePersonName"
          header-align="center"
          align="center"
          label="配置人"
      >
      </el-table-column>

      <el-table-column
          prop="updateTime"
          width="200px"
          header-align="center"
          align="center"
          label="配置时间"
      >
      </el-table-column>


      <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="100"
          label="操作"
      >
        <template slot-scope="scope">
          <el-button
              type="text"
              size="small"
              @click="addOrUpdateHandle(scope.row.id)"
          >配置编辑
          </el-button>
          <el-button
              v-if="scope.row.status === 0"
              type="text"
              size="small"
              style="color: rgba(0, 191, 191, 1)"
              @click="changeGiftStatus(scope.row.id, 1)"
          >上架
          </el-button>
          <el-button
              v-if="scope.row.status === 1"
              type="text"
              size="small"
              style="color: rgba(236, 128, 141, 1)"
              @click="changeGiftStatus(scope.row.id, 0)"
          >下架
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
        v-if="addOrUpdateVisible"
        ref="addOrUpdate"
        @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./gift-add-or-update";
import { getCreatedByName } from "@/utils/sysUser";

const sourceDataForm = {
  id: '',
  name: '',
  scene: '',
  status: '',
  forceIndexType: '',
  createPerson: '',
  updatePerson: '',
  countryCodeList: [],
  updateTimeStart: '',
  updateTimeEnd: '',
  forceIndexCondition: '',
  createTimeArr: [],
}

export default {
  data() {
    return {
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      sysUser: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      statusOpt: [
        {
          value: 0,
          label: '下架'
        },
        {
          value: 1,
          label: '上架'
        }
      ],
      sceneOpt: [
        {
          value: 1,
          label: '全部'
        },
        {
          value: 2,
          label: '私聊'
        },
        {
          value: 3,
          label: '群聊'
        }
      ],
      forceIndexTypeOpt: [
        {
          value: 0,
          label: '永久'
        },
        {
          value: 1,
          label: '有效时间'
        }
      ],
      forceIndexConditionOpt: [
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],

      countryCodeOpt: [],
      checkDiscuss: 1,
      url: '',
      optionsTopic: [],
      optionsDiscuss: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
      },
      addOrUpdateVisible: true
    }
  },
  components: {
    AddOrUpdate
  },
  activated() {
    this.getDataList()
  },
  async created() {
    this.sysUser = await this.$store.dispatch("GetSysUser", {});
  },
  methods: {
    refresh() {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.url = this.$http.adornUrl(
          `/sys/oss/upload?token=${this.$cookie.get('token')}`
      )
      this.$http({
        url: this.$http.adornUrl('/admin/gift/page'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          updateTimeStart: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
          updateTimeEnd: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    changeGiftStatus(id, status) {
      const desc = status === 0 ? '下架' : '上架'
      this.$confirm('当前确认将当前礼物' + desc + '？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/admin/gift/updateStatus`),
          method: 'post',
          data: this.$http.adornData({
            id: id,
            status: status
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.getDataList()
            this.$message({
              message: desc + '成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.visible = false
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    exportList() {
      const exportData = {
        ...this.dataForm,
        updateTimeStart: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
        updateTimeEnd: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
      }
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.open(
          this.$http.adornUrl('/admin/gift/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
          "_blank"
      );
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: 1vh 1vw 0 1vw;
}

::v-deep .el-dialog__body {
  padding: 20px 20px;
}
</style>
